<template>
  <v-container style="max-width: 100vw; height: 94vh; padding: 0 !important;
  background:  linear-gradient(90deg, #20107F 0%, #3814DD 50%, #3e15f5 65%);">
    <div class="px-4 pt-8 fill-height">
    </div>
  </v-container>
</template>

<script>

export default {
  name: 'Section4',
}
</script>
